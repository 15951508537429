<template>
  <div class="CorporatePoster">
    <div class="CorporatePoster-title">
      <div class="title">直播间设置-企业海报</div>
      <div>开启状态：
        <a-switch
            style="margin-right: 10px"
            v-model="isShow"
            @change="switchChange(isShow)"
            checked-children="开"
            un-checked-children="关"
            default-checked />
      </div>
    </div>
    <div class="CorporatePoster-content">
      <div class="uploadImg" style="position: relative">
        <div style="position: absolute;width: 100%;text-align: center;">
          <div style="font-size: 40px;color: #d2d0d0;margin-top: 200px">+</div>
          <div style="font-size: 16px;color: #d2d0d0">上传海报</div>
        </div>
        <input
            id="surfacePlot"
            class="upImg"
            accept="image/*"
            type="file"
            @change="surfacePlotBtn"
            ref="surfacePlot" />
        <div class="uploadImg_img">
          <img
              v-if="relayImg"
              alt=""
              style="width: 98%;
              max-height: 690px;
              border-radius: 5px;
              margin-top: 2px;
              background-color: #fff;"
              :src="relayImg"
          />
        </div>
        <div style="position: absolute;top: 5px;right: 10px;z-index: 999">
          <a-icon v-show="relayImg" @click="delCover" style="color: #f82c2c;cursor: pointer;" type="close-circle" />
        </div>
      </div>

      <div style="margin-left: 30px">
        <div>外链</div>
        <div style="margin-top: 20px;margin-bottom: 20px">
          <a-input v-model="link" style="width: 450px" placeholder="请输入"/>
        </div>
        <div><a-button @click="save" type="primary">保存</a-button></div>
      </div>
    </div>
  </div>
</template>

<script>
import {update} from "@/utils/update";
import {putStreamFunc, streamFuncById} from "@/service/liveManage";

export default {
name: "CorporatePoster",
  data(){
    return{
      isShow:false,
      relayImg:'',
      link:'',
    }
  },
  created() {
    this.getContent();
  },
  computed:{
    funList(){
      return this.$store.state.funList;
    }
  },
  methods:{
    //通过funId获取企业海报内容
    async getContent() {
      const response = await streamFuncById(this.funList.id)
      if (response.code === 0) {
        this.relayImg = response.data.imgUrl;
        this.link = response.data.link;
        this.isShow = response.data.isShow ? true:false;
      } else {
        this.$message.warning(response.message);
      }
    },
    save(){
      if(this.relayImg){
        let data = {
          "id": this.funList.id,
          "streamId": this.funList.streamId, //直播间id
          "functionCode": this.funList.functionCode, //功能code值
          "imgUrl": this.relayImg, //图片路径 只有功能code为HB和QYHB时才有值
          "link": this.link, //链接路径 只有功能code为QYHB时才有值
        }
        this.putStreamFuncs(data)
      }else {
        this.$message.warning("请先上传海报图片！")
      }
    },
    // 修改直播间设置功能列表
    async putStreamFuncs(data) {
      const response = await putStreamFunc(data)
      if(response.code === 200){
        this.$message.success("修改成功！");
        await this.getContent();
      }else {
        this.$message.warning(response.message);
      }
    },
    switchChange(isOpen){
      this.isShow = isOpen;
      let data = {
        "id": this.funList.id, //id
        "streamId": this.funList.streamId, //直播间id
        "functionCode": this.funList.functionCode, //功能code值
        "isShow":isOpen ? 1:0  //0 关闭 1开启
      }
      this.putStreamFuncs(data)
    },
    async surfacePlotBtn() {
      let inputDOM = this.$refs.surfacePlot.files[0];
      //判断图片大小
      if (inputDOM.size < 1048576) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.relayImg = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }

      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
      document.getElementById('surfacePlot').value = null
    },
    delCover(){
      this.relayImg = '';
    },
  }
}
</script>

<style scoped lang="scss">
.CorporatePoster{
  width: 100%;
  //border-left: 1px solid #d9d9d9;
  .CorporatePoster-title{
    height: 50px;line-height: 50px;
    border-bottom: 1px solid #F5F5F5;
    display: flex;
    justify-content: space-between;
    .title{
      font-weight: bold;
      margin-left: 10px;
    }
  }
  .CorporatePoster-content{
    padding: 20px;
    display: flex;
    .uploadImg{
      border: #DDDDDD 2px dashed;
      width: 280px;
      min-height: 700px;
      border-radius: 5px;
      text-align: center;
      padding-top: 30px;
      background-color: #fafafa;
    }
    .uploadImg_img{
      position: absolute;
      top: 0;
      width: 100%;
    }
    .upImg{
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;

    }
  }
}
</style>
